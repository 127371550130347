import React, { useContext, useEffect, useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import {
	PromotionBar,
	ResourceBar,
	Navbar,
	StandaloneResourceLink,
	MobileNav,
	NavLink,
	MobileNavLink,
	StandaloneNavLink,
	MobileStandaloneNavLink,
	ResourceLink,
} from 'ww-nav-components';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, makeStyles, Grid } from '@material-ui/core';

import PromotionContext from '../../context/PromotionContext';
import FormModalContext from '../../context/FormModalContext';
import LitePageContext from '../../context/LitePageContext';
import UkPageContext from '../../context/UkPageContext';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		background: theme.white,
		color: theme.workwaveBlue,
		padding: '0 0',
		borderTop: `1.3px ${theme.lightGray} solid`,
		minHeight: '64px',
		display: 'flex',
		justifyContent: 'center',
	},
	cta: {
		fontSize: '20px',
		marginLeft: '10px',
		backgroundColor: '#3FA529',
		color: 'white',
		'&:hover': {
			backgroundColor: '#178d00',
		},
		[theme.breakpoints.down('lg')]: {
			padding: '12px 20px',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '16px',
		},
	},
	img: {
		height: '3rem',
		marginTop: '.5rem',
		[theme.breakpoints.down('xs')]: {
			height: '2.5rem',
		},
	},
	resource: {
		display: 'block',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	mobile: {
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	desktop: {
		display: 'contents',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
}));

export const Nav = () => {
	const { nav } = useStaticQuery(graphql`
		{
			nav: sanityNav {
				title
				promotionBar {
					_rawContent
				}
				resourceBar {
					productLinks {
						title
						type
						mainName
						secondaryName
						category
						subcategory
						description
						slug {
							current
						}
						internalLink
					}
					productNavHeader
					productMobileFaIcon
					productCalloutFaIcon
					productCalloutTitle
					_rawProductCalloutContent
					ukLink
					mktplcLink
					salesMobileFaIcon
					salesNavHeader
					salesHeader
					resourceSalesLink {
						title
						type
						faIcon
						iconColor {
							hexValue
						}
						name
						description
						slug {
							current
						}
					}
					supportNavHeader
					supportMobileFaIcon
					supportHeader
					resourceSupportLink {
						title
						type
						faIcon
						iconColor {
							hexValue
						}
						name
						description
						slug {
							current
						}
					}
				}
				rgNavLogo {
					asset {
						gatsbyImageData(placeholder: BLURRED, height: 60, width: 245)
					}
				}
				whyRealGreenMobileFaIcon
				whyRealGreenNavHeader
				whyRealGreenLinks {
					title
					type
					faIcon
					iconColor {
						hexValue
					}
					name
					slug {
						current
					}
					internalLink
				}
				whyRealGreenCalloutCtas {
					image {
						asset {
							gatsbyImageData(placeholder: BLURRED, height: 240, width: 310)
						}
					}
					_rawContent
					link
					internalLink
				}
				featuresMobileFaIcon
				featuresNavHeader
				featuresLinks {
					title
					type
					faIcon
					iconColor {
						hexValue
					}
					name
					slug {
						current
					}
					internalLink
				}
				featuresCalloutTitle
				featuresCalloutFaIcon
				_rawFeaturesCalloutContent
				featuresCalloutCtas {
					image {
						asset {
							gatsbyImageData(placeholder: BLURRED, width: 650, height: 240)
						}
					}
					_rawContent
				}
				industryMobileFaIcon
				industryNavHeader
				industryLinks {
					title
					type
					faIcon
					iconColor {
						hexValue
					}
					name
					slug {
						current
					}
					internalLink
				}
				industryCalloutTitle
				industryCalloutFaIcon
				_rawIndustryCalloutContent
				pricingMobileFaIcon
				pricingLink {
					title
					slug {
						current
					}
					internalLink
				}
				resourcesMobileFaIcon
				resourcesNavHeader
				resourcesLinks {
					title
					type
					faIcon
					iconColor {
						hexValue
					}
					name
					slug {
						current
					}
					internalLink
				}
				resourcesCalloutTitle
				resourcesCalloutFaIcon
				_rawResourcesCalloutContent
				loginLink {
					title
					slug {
						current
					}
					internalLink
				}
				loginMobileFaIcon
				ctaText
			}
		}
	`);

	//only uncomment to make changes in nav content. nav hover makes it tough to make styling changes. This will freeze the nav open with devtools open after 5 seconds
	// useEffect(() => {
	// 	setTimeout(function () {
	// 		debugger;
	// 	}, 5000);
	// }, []);

	const classes = useStyles();
	const med = useMediaQuery('(max-width: 960px)');

	const { open, setOpen } = useContext(PromotionContext);
	const { handleModalClick } = useContext(FormModalContext);
	const { isLite } = useContext(LitePageContext);
	const { isUk } = useContext(UkPageContext);

	const handleSalesClick = (e) => {
		e.preventDefault();
		navigate('/contact-us');
	};

	const {
		promotionBar,
		resourceBar,
		whyRealGreenLinks,
		whyRealGreenNavHeader,
		whyRealGreenCalloutCtas,
		whyRealGreenMobileFaIcon,
		ctaText,
		rgNavLogo,
		featuresNavHeader,
		featuresMobileFaIcon,
		featuresLinks,
		featuresCalloutTitle,
		featuresCalloutFaIcon,
		_rawFeaturesCalloutContent,
		industryMobileFaIcon,
		industryNavHeader,
		industryLinks,
		industryCalloutTitle,
		industryCalloutFaIcon,
		_rawIndustryCalloutContent,
		pricingLink,
		pricingMobileFaIcon,
		resourcesMobileFaIcon,
		resourcesNavHeader,
		resourcesLinks,
		resourcesCalloutTitle,
		resourcesCalloutFaIcon,
		_rawResourcesCalloutContent,
		loginLink,
		loginMobileFaIcon,
		featuresCalloutCtas,
	} = nav;

	const [supportLink, setSupportLink] = useState([]);
	const [salesLink, setSalesLink] = useState([]);

	const litePage = () => {
		if (isLite) {
			let liteLinks = resourceBar.resourceSupportLink.map((link, index) => {
				if (index === 0) {
					return {
						...link,
						title: 'Support: +1 (800) 762-0301 (Opt. 3)',
						name: '+1 (800) 762-0301 (Opt. 3)',
						slug: {
							current: 'tel:+18007620301',
						},
					};
				}
				return link;
			});

			setSupportLink(liteLinks);
		} else {
			setSupportLink([...resourceBar.resourceSupportLink]);
		}
	};

	const ukPage = () => {
		if (isUk) {
			let ukLinks = resourceBar.resourceSalesLink.map((link, index) => {
				if (index === 0) {
					return {
						...link,
						title: 'Sales: +44(0) 370 626 0400 (Opt. 2)',
						name: '+44(0) 370 626 0400 (Opt. 2)',
						slug: {
							current: 'tel:+4403706260400',
						},
					};
				}
				return link;
			});

			setSalesLink(ukLinks);
		} else {
			setSalesLink([...resourceBar.resourceSalesLink]);
		}
	};

	useEffect(() => {
		litePage();
	}, [isLite]);

	useEffect(() => {
		ukPage();
	}, [isUk]);

	return (
		<>
			{promotionBar?._rawContent.length ? (
				<PromotionBar
					promotion={promotionBar}
					open={open}
					setOpen={setOpen}
					barColor='#d10066'
					ucText
				/>
			) : null}

			<Grid item className={classes.resource}>
				<ResourceBar>
					<ResourceLink
						header={resourceBar.productNavHeader}
						links={resourceBar.productLinks}
						calloutIcon={resourceBar.productCalloutFaIcon}
						calloutTitle={resourceBar.productCalloutTitle}
						calloutContent={resourceBar._rawProductCalloutContent}
						resourceBar
					/>

					<ResourceLink
						header={resourceBar.salesNavHeader}
						contentHeader={resourceBar.salesHeader}
						links={salesLink}
						handleModalClick={handleSalesClick}
						internalLink
					/>
					<ResourceLink
						header={resourceBar.supportNavHeader}
						contentHeader={resourceBar.supportHeader}
						links={supportLink}
						handleModalClick={handleModalClick}
						internalLink
					/>
					<StandaloneResourceLink
						link='uk/'
						title={resourceBar.ukLink}
						internal
						// icon='earth-europe'
					/>
				</ResourceBar>
			</Grid>

			<Navbar image={rgNavLogo.asset.gatsbyImageData}>
				{/* mobile nav responsive hide gets controlled in ww-nav-components */}
				<MobileNav ctaText={ctaText} handleModalClick={handleModalClick}>
					<MobileNavLink
						header={whyRealGreenNavHeader}
						links={whyRealGreenLinks}
						icon={whyRealGreenMobileFaIcon}
					/>
					<MobileNavLink
						header={featuresNavHeader}
						links={featuresLinks}
						icon={featuresMobileFaIcon}
					/>
					<MobileNavLink
						header={industryNavHeader}
						links={industryLinks}
						icon={industryMobileFaIcon}
					/>
					<MobileStandaloneNavLink
						link={pricingLink.title}
						title={pricingLink.title}
						icon={pricingMobileFaIcon}
						internal
					/>
					<MobileNavLink
						header={resourcesNavHeader}
						links={resourcesLinks}
						icon={resourcesMobileFaIcon}
					/>
					<MobileNavLink
						header={resourceBar.productNavHeader}
						links={resourceBar.productLinks}
						icon={resourceBar.productMobileFaIcon}
					/>
					<MobileNavLink
						header={resourceBar.salesNavHeader}
						links={salesLink}
						icon={resourceBar.salesMobileFaIcon}
					/>
					<MobileNavLink
						header={resourceBar.supportNavHeader}
						links={supportLink}
						icon={resourceBar.supportMobileFaIcon}
					/>
					<MobileStandaloneNavLink
						link={loginLink.slug.current}
						title={loginLink.title}
						icon={loginMobileFaIcon}
						internal={loginLink.internalLink}
					/>
					<MobileStandaloneNavLink
						link='uk/'
						title={nav.resourceBar.ukLink}
						icon='earth-europe'
						internal
					/>

					<MobileStandaloneNavLink
						link='https://marketplace.workwave.com'
						title={nav.resourceBar.mktplcLink}
						icon='cart-shopping'
						internal={false}
					/>
				</MobileNav>
				<Grid item className={classes.desktop}>
					<NavLink
						header={whyRealGreenNavHeader}
						links={whyRealGreenLinks}
						calloutCtas={whyRealGreenCalloutCtas}
						// calloutIcon={featuresCalloutFaIcon}
						// calloutTitle={featuresCalloutTitle}
						// calloutContent={_rawFeaturesCalloutContent}
					/>
					<NavLink
						header={featuresNavHeader}
						links={featuresLinks}
						calloutIcon={featuresCalloutFaIcon}
						calloutTitle={featuresCalloutTitle}
						calloutContent={_rawFeaturesCalloutContent}
						calloutCtas={featuresCalloutCtas}
					/>
					<StandaloneNavLink
						link={pricingLink.slug.current}
						title={pricingLink.title}
						internal={pricingLink.internalLink}
					/>
					<NavLink
						header={industryNavHeader}
						links={industryLinks}
						calloutIcon={industryCalloutFaIcon}
						calloutTitle={industryCalloutTitle}
						// calloutContent={_rawIndustryCalloutContent}
					/>
					<NavLink
						header={resourcesNavHeader}
						links={resourcesLinks}
						calloutIcon={resourcesCalloutFaIcon}
						calloutTitle={resourcesCalloutTitle}
						calloutContent={_rawResourcesCalloutContent}
					/>
					<StandaloneNavLink
						link={loginLink.slug.current}
						title={loginLink.title}
						internal={false}
					/>

					<Button
						variant='contained'
						size='medium'
						//color='secondary'
						className={classes.cta}
						onClick={(e) => handleModalClick(e)}>
						{ctaText}
					</Button>
				</Grid>
			</Navbar>
		</>
	);
};
